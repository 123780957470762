* {
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2F75A6;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2F75A6;
}

header {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

header div#theme {
  position: absolute;
  right: 10px;
  font-size: xx-large;
  cursor: pointer;
}

h1 {
  font-family: sans-serif;
  text-decoration: underline;
  color: #2F75A6;
  font-family: "kaushan script";
}

main {
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

div#textarea {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

div#textarea textarea {
  height: 75vh;
  width: 48vw;
  max-height: 80vh;
  resize: none;
  box-shadow: 5px 5px 5px grey;
  border-radius: 5px;
  padding: 5px;
  outline: none;
}

div#textarea textarea:disabled {
  background-color: #2F75A6;
  color: white;
}

div#tools {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

div#tools button {
  background-color: #2F75A6;
  border: #2F75A6;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 17px;
  cursor: pointer;
  color: #ffffff;
  text-transform: capitalize;
  font-family: "kaushan script";
}

div#tools span {
  font-family: "kaushan script";
}

footer {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "kaushan script";
}